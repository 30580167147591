import {
  DashboardOutlined,
  DollarOutlined,
  FileTextOutlined,
  HomeOutlined,
  ShopOutlined,
  UserOutlined
} from '@ant-design/icons';
import { Divider, Menu } from 'antd';
import React, { ReactElement } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { UI_ROUTES } from '../../../shared/utils/constants';

const WMSMenuList = (): ReactElement => {
  const location = useLocation();

  return (
    <Menu
      mode="inline"
      selectedKeys={[location.pathname]}
      style={{ height: '100%', borderRight: 0 }}
    >
      <Menu.Item key={`${UI_ROUTES.WMS}${UI_ROUTES.DASHBOARD}`} icon={<DashboardOutlined />}>
        <NavLink to={`${UI_ROUTES.WMS}${UI_ROUTES.DASHBOARD}`}>数据总览</NavLink>
      </Menu.Item>
      <Menu.Item key={`${UI_ROUTES.WMS}${UI_ROUTES.WAREHOUSES}`} icon={<HomeOutlined />}>
        <NavLink to={`${UI_ROUTES.WMS}${UI_ROUTES.WAREHOUSES}`}>仓库列表</NavLink>
      </Menu.Item>
      <Menu.Item key={`${UI_ROUTES.WMS}${UI_ROUTES.ORDERS}`} icon={<FileTextOutlined />}>
        <NavLink to={`${UI_ROUTES.WMS}${UI_ROUTES.ORDERS}`}>电商订单</NavLink>
      </Menu.Item>
      <Menu.Item key={`${UI_ROUTES.WMS}${UI_ROUTES.VENDORS}`} icon={<ShopOutlined />}>
        <NavLink to={`${UI_ROUTES.WMS}${UI_ROUTES.VENDORS}`}>供应商</NavLink>
      </Menu.Item>
      <Divider />
      <Menu.Item key={`${UI_ROUTES.WMS}${UI_ROUTES.OVERSEA_WAREHOUSES}`} icon={<UserOutlined />}>
        <NavLink to={`${UI_ROUTES.WMS}${UI_ROUTES.OVERSEA_WAREHOUSES}`}>海外仓客户</NavLink>
      </Menu.Item>
      <Menu.Item key={`${UI_ROUTES.WMS}${UI_ROUTES.HWC_WAREHOUSES}`} icon={<HomeOutlined />}>
        <NavLink to={`${UI_ROUTES.WMS}${UI_ROUTES.HWC_WAREHOUSES}`}>海外仓列表</NavLink>
      </Menu.Item>
      <Menu.Item key={`${UI_ROUTES.WMS}${UI_ROUTES.HWC_PARTNERS}`} icon={<ShopOutlined />}>
        <NavLink to={`${UI_ROUTES.WMS}${UI_ROUTES.HWC_PARTNERS}`}>合作海外仓</NavLink>
      </Menu.Item>
      <Menu.Item key={`${UI_ROUTES.WMS}${UI_ROUTES.HWC_FEE}`} icon={<DollarOutlined />}>
        <NavLink to={`${UI_ROUTES.WMS}${UI_ROUTES.HWC_FEE}`}>操作费模板</NavLink>
      </Menu.Item>
      <Menu.Item key={`${UI_ROUTES.WMS}${UI_ROUTES.HWC_ACCOUNTING}`} icon={<FileTextOutlined />}>
        <NavLink to={`${UI_ROUTES.WMS}${UI_ROUTES.HWC_ACCOUNTING}`}>财务对账</NavLink>
      </Menu.Item>
    </Menu>
  );
};

export default WMSMenuList;
