import { MonitorOutlined, PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import React, { ReactElement, useEffect } from 'react';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { HwcBill } from '../../../../custom_types/overseawarehouse/hwc-bill';
import {
  fetchHwcClientBillsHandler,
  selectHwcClientBillLodaing,
  selectHwcClientBills,
  setShowModal,
  setShowTopUpModal
} from '../../../../redux/hwc/hwcClientBillSlice';
import HwcClientBillCreateModal from './HwcClientBillCreateModal';
import HwcClientBillTopUpModal from './HwcClientBillTopUpModal';
// import LabelUserBillModal from './LabelUserBillModal';

interface HwcClientBillPanelProps {
  overseaWarehouseId: string;
}

const HwcClientBillPanel = ({ overseaWarehouseId }: HwcClientBillPanelProps): ReactElement => {
  const dispatch = useDispatch();
  const bills = useSelector(selectHwcClientBills);
  const loading = useSelector(selectHwcClientBillLodaing);

  useEffect(() => {
    dispatch(fetchHwcClientBillsHandler(overseaWarehouseId));
  }, [dispatch, overseaWarehouseId]);

  const columns = [
    {
      title: '序号',
      key: 'index',
      render: (_: any, __: any, index: number): ReactElement => {
        return <span>{index + 1}</span>;
      }
    },
    {
      title: '账单编码',
      key: 'code',
      dataIndex: 'code'
    },
    { title: '账单类型', key: 'type', dataIndex: 'type' },
    { title: '订单号', key: 'orderId', dataIndex: 'orderId' },
    { title: '仓库编码', key: 'ck_num', dataIndex: 'ck_num' },
    {
      title: '结算日期',
      key: 'date',
      dataIndex: 'date',
      render: (date: string): ReactElement => {
        return (
          <div className="transation_table_cell">
            <div>
              <FormattedDate value={new Date(date)} year="numeric" month="2-digit" day="2-digit" />
            </div>
            <div>
              <FormattedTime value={new Date(date)} />
            </div>
          </div>
        );
      }
    },
    {
      title: '操作费',
      key: 'operationFee',
      dataIndex: 'operationFee',
      render: (_: any, record: HwcBill): ReactElement => {
        return (
          <>
            {record.operationFee.length === 0
              ? 0
              : record.operationFee.reduce((acc, cur) => acc + cur.amount, 0).toFixed(2)}
          </>
        );
      }
    },
    {
      title: '物流费',
      key: 'shippingFee',
      dataIndex: 'shippingFee',
      render: (_: any, record: HwcBill): ReactElement => {
        return <>{record.shippingFee.toFixed(2)}</>;
      }
    },
    {
      title: '仓储费',
      key: 'storageFee',
      dataIndex: 'storageFee',
      render: (_: any, record: HwcBill): ReactElement => {
        return <>{record.storageFee.toFixed(2)}</>;
      }
    },
    {
      title: '其他费用',
      key: 'otherFee',
      dataIndex: 'otherFee',
      render: (_: any, record: HwcBill): ReactElement => {
        return <>{record.otherFee.toFixed(2)}</>;
      }
    },
    {
      title: '财务状态',
      key: 'accountingStatus',
      dataIndex: 'accountingStatus',
      render: (accountingStatus: string, record: HwcBill) => {
        if (!accountingStatus) return '-';
        return <div>{`${accountingStatus} (${record.accountingDiff?.toFixed(2)})`}</div>;
      }
    },
    {
      title: '备注',
      key: 'remark',
      dataIndex: 'remark'
    }
  ];

  return (
    <div>
      <HwcClientBillCreateModal warehouseId={overseaWarehouseId} />
      <HwcClientBillTopUpModal warehouseId={overseaWarehouseId} />
      <PageHeader
        title=""
        extra={[
          <Button
            icon={<SyncOutlined spin={loading} />}
            onClick={() => dispatch(fetchHwcClientBillsHandler(overseaWarehouseId))}
          />,
          <Button
            key="1"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => dispatch(setShowModal(true))}
          >
            添加账单
          </Button>,
          <Button
            key="2"
            type="primary"
            icon={<MonitorOutlined />}
            onClick={() => dispatch(setShowTopUpModal(true))}
          >
            调整余额
          </Button>
        ]}
      />
      <Table<HwcBill>
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={bills}
        loading={loading}
      />
    </div>
  );
};

export default HwcClientBillPanel;
